<template>
  <div class="login-wrapper">
    <div class="content">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="on"
      >
        <div class="title-container">
          <h3
            class="title"
            data-name="BIFROST SERVER"
          >
            BIFROST SERVER
          </h3>
        </div>
        <el-form-item prop="userName">
          <el-input
            ref="userName"
            v-model.trim="loginForm.userName"
            placeholder="Account"
            prefix-icon="el-icon-user"
            name="userName"
            type="text"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>
        <el-form-item prop="passWord">
          <el-input
            ref="password"
            v-model.trim="loginForm.passWord"
            :type="showPassword ? '' : 'password'"
            prefix-icon="el-icon-lock"
            placeholder="Password"
            name="password"
            auto-complete="on"
            @keyup.enter.native="handleLogin"
          >
            <i
              slot="suffix"
              class="fa"
              :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"
              @click="showPwd"
            />
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :loading="loading"
            @click="handleLogin"
          >
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'Login',
  data () {
    const validateuserName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please enter the user name'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('The password can not be less than 6 digits'))
      } else if (value.length > 20) {
        callback(new Error('The password can not be more than 20 digits'))
      } else {
        callback()
      }
    }
    return {
      loading: false,
      loginForm: {
        userName: '',
        passWord: ''
      },
      loginRules: {
        userName: [{ required: true, trigger: 'blur', validator: validateuserName }],
        passWord: [{ required: true, trigger: 'blur', validator: validatePassword }]
      },
      showPassword: false
    }
  },
  mounted () {
    if (this.loginForm.userName === '') {
      this.$refs.userName.focus()
    } else if (this.loginForm.passWord === '') {
      this.$refs.password.focus()
    }
    // this.SET_USERINFO({})
  },
  methods: {
    ...mapActions('user', ['loginByUsername']),
    ...mapMutations('user', ['SET_USERINFO']),
    showPwd () {
      this.showPassword = !this.showPassword
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.loginByUsername(this.loginForm)
            .then((res) => {
              if (res.code === 200) {
                this.$router.push({ path: this.redirect || '/' })
              }
            }).finally(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less">
  .login-wrapper{
    .el-input {
      display: inline-block;
      height: 47px;
      input {
        background: white;
        border: 0;
        border-bottom: 1px solid @primary;
        -webkit-appearance: none;
        border-radius: 0;
        color: #333333;
        height: 47px;
        caret-color: #333333;
        &:-webkit-autofill {
          box-shadow: 0 0 0 1000px white inset !important;
          -webkit-text-fill-color: #333333 !important;
        }
      }
    }
  }
</style>
<style scoped lang="less">
    .login-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      min-height: 100vh;
      background-color: @primary;
      overflow: hidden;

      &:before, &:after {
        content: "";
        position: absolute;
        left: 50%;
        min-width: 300vw;
        min-height: 300vw;
        background-color: #fff;
        animation-name: rotate;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      &:before {
        bottom: 15vh;
        border-radius: 45%;
        animation-duration: 20s;
      }

      &:after {
        bottom: 12vh;
        opacity: .5;
        border-radius: 47%;
        animation-duration: 20s;
      }

      .content{
        position: relative;
        color: #333;
        z-index: 10;
        text-align: center;
        width: 450px;
        .title-container{
          text-align: center;
          line-height: 50px;
          h3{
            position: relative;
            text-align: center;
            color: @embellish;
            font-size: 24px;
          }
        }
        button{
          width: 100%;
        }
      }
  }

    @keyframes rotate {
      0% {
        transform: translate(-50%, 0) rotateZ(0deg);
      }
      50% {
        transform: translate(-50%, -2%) rotateZ(180deg);
      }
      100% {
        transform: translate(-50%, 0%) rotateZ(360deg);
      }
    }
</style>
